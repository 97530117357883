<template>
  <div id="end">
    <h1>OK !</h1>
    <div>
      <img srcset="../assets/ok@2x.png 2x ../assets/ok@3x.png 3x" src="../assets/ok.png"/>
    </div>

    <div>
      <button @click="end">Terminer</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    end() {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
#end {
  h1 {
    color: #FFF;
    margin-bottom: 30px;
  }

  button {
    background-color: #00a0ee;
    margin-top: 40px;
  }
}
</style>